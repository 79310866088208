@import url(https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
:root {
  --font-base: 'Vazir';
  --font-alt: 'Vazir';
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  direction: rtl;
}

a {
  color: #FFFFFF !important;
  color: var(--color-white) !important;
  text-decoration: none !important;
}

.justify{
  text-align: justify;
} 

.slide-bottom {
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0);
  }
}

.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}


.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: right;
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 2rem 0;
    color: var(--color-grey);
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }

    .app__aboutus-content_knife img {
        height: 450px;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}
.app__newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: var(--color-black);
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: row;
    margin-top: 3rem;
}

.app__newsletter-input input {
    width: 620px;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
}

.app__newsletter-input button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
        width: 100%;
    }

    .app__newsletter-input input {
        margin: 0 0 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 2rem 0;
        border: none;
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}

.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 25%;
    background: var(--color-black);
}

.app__footerOverlay-img {
    height: 75%;
}

.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1 1;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    font-family: 'Vazir';
    padding: 1rem 4rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {
    color: var(--color-grey);
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.reservelink>svg{
    margin-left: 7px;
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
        display: none;
    }

    .app__navbar-logo img {
        width: 110px;
    }
}
.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 5rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}


.app__chef-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__chef-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .app__chef-sign img {
        width: 80%;
    }
}
.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background: var(--color-black);
    padding-top: 0;
}

.app__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 5rem;
    padding: 0 2rem;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1 1;
    margin: 1rem;
    text-align: center;
}

.app__footer-links_logo img:first-child {
    width: 210px;
    margin-bottom: 0.75rem;
}


.app__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.app__footer-links_icons {
    margin-top: 0.5rem;
}

.app__footer-links_icons svg {
    color: var(--color-white);
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: var(--color-golden);
}

.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.footer__copyright {
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__footer-headtext {
        font-size: 51px;
        line-height: 61.6px;
    }
}

@media screen and (max-width: 1150px) {
    .app__footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    
    .app__footer-links_logo,
    .app__footer-links_work {
        margin: 2rem 0;
        width: 100%;
    }
    .app__footer-links_contact{
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .app__footer {
        padding: 0 0 2rem 0;
    }}

@media screen and (max-width: 350px) {
    .app__footer-links_logo img:first-child {
        width: 80%;
    }

}

.app__gallery {
    flex-direction: row;

    background: var(--color-black);
    padding: 4rem 0 4rem 6rem;
}

.app__gallery-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery__arrow-icon {
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}

.app__video {
    height: 100vh;
    position: relative;
    
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.65);
}

.app__video-overlay_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}
.app__header {
    position: absolute;
    z-index: 1;
}

.app__header-h1 {
    font-family: 'Leyla';
    color: var(--color-golden);
    font-weight: 500;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

.welcome{
    width: 58% !important;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}

.app__laurels_awards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 3rem;
}

.app__laurels_awards-card {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
}

@media screen and (min-width: 1900px) {
    .app__laurels_awards-card {
        min-width: 390px;
    }
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

@media screen and (max-width: 450px) {
    .app__laurels_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
}

.app__specialMenu {
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 410px;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

@media screen and (min-width: 2000px) {
    .app__specialMenu-menu_img {
        width: 650px;
    }

    .app__specialMenu-menu_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}

.container {
    font-family: "Vazir";

}

.title{
    text-align: center;
    margin: 20px;
}


.mainzone{
text-align: center;
cursor: pointer;
}

.reserved{
  cursor:not-allowed !important;
}

.mainzoneimg{
  width: 550px;
}

.saloncontainer{
  display: flex;
  height: 150px;
}

.leftsalonimg{
  width: 170px;
}

.rightsalonimg{
  width : 190px;
}

.saloncontainer{
  position: relative;
}

.leftsalon{
  position: absolute;
left: 298px;
bottom: 12px;
cursor: pointer;
}

.rightsalon{
  position: absolute;
  right: 359px;
  bottom: 21px;
  cursor: pointer;
}

.cafecontainer{
  display: flex;
  height : 513px;
  position: relative;
}

.rightcafeimg{
  width: 180px;
}

.leftcafeimg{
  width: 180px;
}

.leftcafe{
position: absolute;
right: 200px;
bottom: 27px;
cursor: pointer;

}
.rightcafe{
position: absolute;
left: 200px;
cursor: pointer;
}

.lowopacity{
  opacity: 0.3;
}

.relpos{
  position: relative;
  cursor: default;
}

.wtrascontainer{
  text-align: center;
  position: relative;
}

.wsalonimg{
  width: 240px;
}

.server-error{
  text-align: center;
}

.table1{
  width: 61px;
position: absolute;
top: 129px;
left: 405px;
cursor: pointer;
}

.table2{
  width: 50px;
  position: absolute;
  left: 353px;
  cursor: pointer;
  bottom: 47px;
}

.table3{
  width: 40px;
  position: absolute;
  left: 439px;
  cursor: pointer;
  bottom: 35px;
}

.table4{
  width: 48px;
  position: absolute;
  cursor: pointer;
  bottom: 143px;
  right: 372px;
}

.table5{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 71px;
  right: 372px;
  rotate: 90deg;
}
.table6{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 155px;
  right: 425px;
  
}

.table7{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 155px;
  right: 473px;
}

.table8 {
  width: 56px;
  position: absolute;
  cursor: pointer;
  bottom: 94px;
  right: 426px;
}

.table9 {
  width: 56px;
  position: absolute;
  cursor: pointer;
  bottom: 94px;
  right: 489px;
  rotate: 90deg;
}
.table10{
  width: 38px;
  position: absolute;
  cursor: pointer;
  bottom: 27px;
  right: 491px;
}

.table11{
  width: 50px;
  position: absolute;
  cursor: pointer;
  bottom: 39px;
  right: 411px;
}

.table12{
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 467px;
}

.table13{
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 529px;
}

.table14{
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 591px;
}

.table15{
  width: 43px;
  position: absolute;
  cursor: pointer;
  bottom: 43px;
  left: 472px;
}

.table16{
  width: 43px;
  position: absolute;
  cursor: pointer;
  bottom: 48px;
  left: 528px;
}

.table17{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 37px;
  left: 585px;
}

.table18{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 460px;
rotate: 90deg;
}

.table19{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 510px;
rotate: 90deg;
}

.table20{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 559px;
rotate: 90deg;
}

.table21{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 609px;
rotate: 90deg;
}

.table22{
  width: 52px;
position: absolute;
cursor: pointer;
bottom: 41px;
left: 463px;
}

.table23{
  width: 52px;
position: absolute;
cursor: pointer;
bottom: 41px;
left: 531px;
}

.table24{
  width: 33px;
position: absolute;
cursor: pointer;
bottom: 41px;
left: 601px;
}

.table25{
  width: 36px;
  position: absolute;
  cursor: pointer;
  top: 96px;
  left: 532px;
  rotate: 90deg;
}

.table26{
  width: 43px;
position: absolute;
cursor: pointer;
top: 156px;
left: 432px;
rotate: 90deg;
}

.table27{
  width: 36px;
position: absolute;
cursor: pointer;
top: 164px;
left: 527px;
rotate: 90deg;
}

.table28{
  width: 45px;
position: absolute;
cursor: pointer;
bottom: 236px;
right: 609px;
rotate: 90deg;
}

.table29{
  width: 36px;
  position: absolute;
  cursor: pointer;
  bottom: 177px;
  right: 549px;
  rotate: 90deg;
}

.table30{
  width: 45px;
  position: absolute;
  cursor: pointer;
  bottom: 88px;
  right: 614px;
  rotate: 90deg;
}

.table31{
  width: 56px;
  position: absolute;
  cursor: pointer;
  bottom: 88px;
  right: 540px;
  rotate: 90deg;
}

.table32{
  width: 53px;
position: absolute;
cursor: pointer;
top: 200px;
left: 517px;
rotate: 90deg;
}


.table33{
  width: 53px;
position: absolute;
cursor: pointer;
top: 200px;
left: 588px;
rotate: 90deg;
}


.table34{
  width: 53px;
position: absolute;
cursor: pointer;
top: 292px;
left: 517px;
rotate: 90deg;
}


.table35{
  width: 53px;
position: absolute;
cursor: pointer;
top: 292px;
left: 588px;
rotate: 90deg;
}


.table36{
  width: 53px;
  position: absolute;
  cursor: pointer;
  top: 462px;
  left: 483px;
  rotate: 90deg;
}


.table37{
  width: 53px;
  position: absolute;
  cursor: pointer;
  top: 462px;
  left: 571px;
  rotate: 90deg;
}


.table38{
  width: 47px;
  position: absolute;
  cursor: pointer;
  bottom: 49px;
  left: 484px;
  rotate: 90deg;
}

.table39{
  width: 47px;
  position: absolute;
  cursor: pointer;
  bottom: 49px;
  left: 568px;
  rotate: 90deg;
}

.rmdp-input {
  border: none !important;
height: 19px;
margin: 0;
padding: 0;
text-align: center;
background: none;
color: var(--color-black);
}

.input-title{
  font-family: "shabnam";
}

.rmdp-input:focus{
  outline: none;
  box-shadow: none !important;
}

.nav-brand {
  cursor: pointer;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .nav-brand {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .nav-brand {
    font-size: 2.8rem;
  }
}
.pizza-slice {
  font-size: 1.2rem;
  padding-left: 1.25%;
  color: #e74c3c;
}

.pizza-cta {
  padding-top: 4%;
}

.looking-for-pizza {
  font-size: 2.5rem;
  padding-bottom: 1.8%;
  padding-right: 3%;
  padding-left: 3%;
}

.book-table-btn {
  color: white;
  font-size: 1.2rem;
  padding: 2% 8%;
  border-radius: 25px;
  background-color: #e74c3c;
  border: 2px white solid;
  font-family: 'shabnam';
}
.book-table-btn:hover {
  color: #e74c3c;
  background-color: white !important;
  border: 2px solid black !important;
}

@media (min-width: 768px) {
  .book-table-btn {
    font-size: 1.4rem;
    padding: 0.5% 5%;
  }
}
.big-img-container {
  padding: 10% 0;
}

@media (min-width: 768px) {
  .big-img-container {
    padding: 4% 0;
  }
}
.big-img {
  width: 96%;
  border-radius: 25px;
  border-bottom-left-radius: 25px;
}

.selected-table {
  font-size: 1.25rem;
  padding-bottom: 2%;
}

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

.booking-dropdown {
  width: 85%;
  padding: 4% 0;
  background-color: var(--color-golden);
  color: var(--color-black);
  margin: 2% 0;
  font-family: "shabnam";
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  text-align: center;
}
.booking-dropdown:hover {
  background-color: var(--color-golden);
  color: var(--color-black);
}
.booking-dropdown:focus {
  outline: none;
  box-shadow: none;
}
.booking-dropdown-menu {
  outline: none;
}
.booking-dropdown-item:hover {
  background-color: white;
}
.booking-dropdown-item:active {
  background-color: white;
  color: black;
}

@media (min-width: 768px) {
  .booking-dropdown {
    font-size: 1.2rem;
  }
}
.table-display-message {
  padding: 2% 0;
  text-align: center;
  font-size: 1.3rem;
  color: var(--color-black);
}

.tables-display {
  background-color: var(--color-golden);
  margin: 10% 2%;
  padding: 3% 5%;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .tables-display {
    margin: 6% 2.5%;
  }
}
.table-key {
  padding-top: 1%;
  padding-bottom: 4.5%;
  color: white;
}

.available-tables {
  color: white;
  font-size: 1.2rem;
  font-family: 'shabnam';
}

.table-container {
  margin: 0 1%;
}

.table {
  background-color: rgba(245, 246, 250, 0.4);
  padding: 15px;
  border-radius: 25px;
  min-width: 80px;
}

.table-row {
  margin-bottom: 8px;
}

.selectable-table:hover {
  cursor: pointer;
}
.selectable-table:hover-row {
  padding: 5px;
}

.full-table {
  background-color: white;
  border: solid 2px white;
  border-radius: 50%;
  padding: 1px 10px;
  margin: 3px;
}

.empty-table {
  background-color: #e74c3c;
  border: solid 2px white;
  border-radius: 50%;
  padding: 1px 10px;
  margin: 3px;
}

.table-name {
  color: white;
  font-size: 0.8rem;
  padding-top: 15px;
  font-family: Roboto, sans-serif;
}

.reservation-details-container {
  padding: 0 4%;
  padding-bottom: 5%;
}

.reservation-details {
  margin: 0 2%;
  margin-bottom: 5%;
}

@media (min-width: 768px) {
  .reservation-details {
    margin-bottom: 0;
  }
}
.reservation-input:focus {
  border-color: black;
  box-shadow: 0 0;
}

.reservation-error {
  color: #c0392b;
  font-size: 1.1rem;
  padding-bottom: 3%;
}

.thanks-header {
  padding: 5% 0;
  padding-top: 10%;
  font-size: 2.5rem;
}

.thanks-subtext {
  font-size: 1.2rem;
  padding: 2%;
}

.thank-you-pizza {
  font-size: 4rem;
  color: #e74c3c;
  padding-bottom: 8%;
}

@media (min-width: 768px) {
  .thanks-header {
    font-size: 5.5rem;
  }

  .thanks-subtext {
    font-size: 2.2rem;
  }

  .thank-you-pizza {
    font-size: 7rem;
    padding-bottom: 4%;
  }
}

/*# sourceMappingURL=index.css.map */

.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url(/static/media/bg.3d421b1b.png);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-alt);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 45px;
}

@font-face {
  font-family: 'Vazir';
    src: url(/static/media/Vazir.bb48ff43.ttf);
  }

  @font-face {
    font-family: 'Leyla';
      src: url(/static/media/Leyla.9b3830ac.ttf);
    }

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}

.admincontainer{
  display: flex;
  font-family: 'shabnam';
}

.sidebar{
  flex: 2 1;
  text-align: center;
  padding: 8px 0 0 0;
  margin-top: 40px;
}

.orderstatus{
  cursor: pointer;
}

.orderstatus.cancel{
  color : red;
}

.sidebarbtn{
  background-color: gold;
  padding: 10px;
  cursor: pointer;
  border-bottom:2px solid gray ;
}

.sidebar-title{
  padding: 10px;
border-bottom:2px solid gray ;
}


.main{
  flex: 11 1;
text-align: center;
margin: 40px 40px 0 40px;
background-color: #e9ecef;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: center;
}

tr:nth-child(even){background-color: #f2f2f2}

th {
  background-color: #04AA6D;
  color: white;
}
