
.rmdp-input {
  border: none !important;
height: 19px;
margin: 0;
padding: 0;
text-align: center;
background: none;
color: var(--color-black);
}

.input-title{
  font-family: "shabnam";
}

.rmdp-input:focus{
  outline: none;
  box-shadow: none !important;
}

.nav-brand {
  cursor: pointer;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .nav-brand {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .nav-brand {
    font-size: 2.8rem;
  }
}
.pizza-slice {
  font-size: 1.2rem;
  padding-left: 1.25%;
  color: #e74c3c;
}

.pizza-cta {
  padding-top: 4%;
}

.looking-for-pizza {
  font-size: 2.5rem;
  padding-bottom: 1.8%;
  padding-right: 3%;
  padding-left: 3%;
}

.book-table-btn {
  color: white;
  font-size: 1.2rem;
  padding: 2% 8%;
  border-radius: 25px;
  background-color: #e74c3c;
  border: 2px white solid;
  font-family: 'shabnam';
}
.book-table-btn:hover {
  color: #e74c3c;
  background-color: white !important;
  border: 2px solid black !important;
}

@media (min-width: 768px) {
  .book-table-btn {
    font-size: 1.4rem;
    padding: 0.5% 5%;
  }
}
.big-img-container {
  padding: 10% 0;
}

@media (min-width: 768px) {
  .big-img-container {
    padding: 4% 0;
  }
}
.big-img {
  width: 96%;
  border-radius: 25px;
  border-bottom-left-radius: 25px;
}

.selected-table {
  font-size: 1.25rem;
  padding-bottom: 2%;
}

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

.booking-dropdown {
  width: 85%;
  padding: 4% 0;
  background-color: var(--color-golden);
  color: var(--color-black);
  margin: 2% 0;
  font-family: "shabnam";
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  text-align: center;
}
.booking-dropdown:hover {
  background-color: var(--color-golden);
  color: var(--color-black);
}
.booking-dropdown:focus {
  outline: none;
  box-shadow: none;
}
.booking-dropdown-menu {
  outline: none;
}
.booking-dropdown-item:hover {
  background-color: white;
}
.booking-dropdown-item:active {
  background-color: white;
  color: black;
}

@media (min-width: 768px) {
  .booking-dropdown {
    font-size: 1.2rem;
  }
}
.table-display-message {
  padding: 2% 0;
  text-align: center;
  font-size: 1.3rem;
  color: var(--color-black);
}

.tables-display {
  background-color: var(--color-golden);
  margin: 10% 2%;
  padding: 3% 5%;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .tables-display {
    margin: 6% 2.5%;
  }
}
.table-key {
  padding-top: 1%;
  padding-bottom: 4.5%;
  color: white;
}

.available-tables {
  color: white;
  font-size: 1.2rem;
  font-family: 'shabnam';
}

.table-container {
  margin: 0 1%;
}

.table {
  background-color: rgba(245, 246, 250, 0.4);
  padding: 15px;
  border-radius: 25px;
  min-width: 80px;
}

.table-row {
  margin-bottom: 8px;
}

.selectable-table:hover {
  cursor: pointer;
}
.selectable-table:hover-row {
  padding: 5px;
}

.full-table {
  background-color: white;
  border: solid 2px white;
  border-radius: 50%;
  padding: 1px 10px;
  margin: 3px;
}

.empty-table {
  background-color: #e74c3c;
  border: solid 2px white;
  border-radius: 50%;
  padding: 1px 10px;
  margin: 3px;
}

.table-name {
  color: white;
  font-size: 0.8rem;
  padding-top: 15px;
  font-family: Roboto, sans-serif;
}

.reservation-details-container {
  padding: 0 4%;
  padding-bottom: 5%;
}

.reservation-details {
  margin: 0 2%;
  margin-bottom: 5%;
}

@media (min-width: 768px) {
  .reservation-details {
    margin-bottom: 0;
  }
}
.reservation-input:focus {
  border-color: black;
  box-shadow: 0 0;
}

.reservation-error {
  color: #c0392b;
  font-size: 1.1rem;
  padding-bottom: 3%;
}

.thanks-header {
  padding: 5% 0;
  padding-top: 10%;
  font-size: 2.5rem;
}

.thanks-subtext {
  font-size: 1.2rem;
  padding: 2%;
}

.thank-you-pizza {
  font-size: 4rem;
  color: #e74c3c;
  padding-bottom: 8%;
}

@media (min-width: 768px) {
  .thanks-header {
    font-size: 5.5rem;
  }

  .thanks-subtext {
    font-size: 2.2rem;
  }

  .thank-you-pizza {
    font-size: 7rem;
    padding-bottom: 4%;
  }
}

/*# sourceMappingURL=index.css.map */
