.mainzone{
text-align: center;
cursor: pointer;
}

.reserved{
  cursor:not-allowed !important;
}

.mainzoneimg{
  width: 550px;
}

.saloncontainer{
  display: flex;
  height: 150px;
}

.leftsalonimg{
  width: 170px;
}

.rightsalonimg{
  width : 190px;
}

.saloncontainer{
  position: relative;
}

.leftsalon{
  position: absolute;
left: 298px;
bottom: 12px;
cursor: pointer;
}

.rightsalon{
  position: absolute;
  right: 359px;
  bottom: 21px;
  cursor: pointer;
}

.cafecontainer{
  display: flex;
  height : 513px;
  position: relative;
}

.rightcafeimg{
  width: 180px;
}

.leftcafeimg{
  width: 180px;
}

.leftcafe{
position: absolute;
right: 200px;
bottom: 27px;
cursor: pointer;

}
.rightcafe{
position: absolute;
left: 200px;
cursor: pointer;
}

.lowopacity{
  opacity: 0.3;
}

.relpos{
  position: relative;
  cursor: default;
}

.wtrascontainer{
  text-align: center;
  position: relative;
}

.wsalonimg{
  width: 240px;
}

.server-error{
  text-align: center;
}

.table1{
  width: 61px;
position: absolute;
top: 129px;
left: 405px;
cursor: pointer;
}

.table2{
  width: 50px;
  position: absolute;
  left: 353px;
  cursor: pointer;
  bottom: 47px;
}

.table3{
  width: 40px;
  position: absolute;
  left: 439px;
  cursor: pointer;
  bottom: 35px;
}

.table4{
  width: 48px;
  position: absolute;
  cursor: pointer;
  bottom: 143px;
  right: 372px;
}

.table5{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 71px;
  right: 372px;
  rotate: 90deg;
}
.table6{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 155px;
  right: 425px;
  
}

.table7{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 155px;
  right: 473px;
}

.table8 {
  width: 56px;
  position: absolute;
  cursor: pointer;
  bottom: 94px;
  right: 426px;
}

.table9 {
  width: 56px;
  position: absolute;
  cursor: pointer;
  bottom: 94px;
  right: 489px;
  rotate: 90deg;
}
.table10{
  width: 38px;
  position: absolute;
  cursor: pointer;
  bottom: 27px;
  right: 491px;
}

.table11{
  width: 50px;
  position: absolute;
  cursor: pointer;
  bottom: 39px;
  right: 411px;
}

.table12{
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 467px;
}

.table13{
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 529px;
}

.table14{
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 59px;
  left: 591px;
}

.table15{
  width: 43px;
  position: absolute;
  cursor: pointer;
  bottom: 43px;
  left: 472px;
}

.table16{
  width: 43px;
  position: absolute;
  cursor: pointer;
  bottom: 48px;
  left: 528px;
}

.table17{
  width: 39px;
  position: absolute;
  cursor: pointer;
  bottom: 37px;
  left: 585px;
}

.table18{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 460px;
rotate: 90deg;
}

.table19{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 510px;
rotate: 90deg;
}

.table20{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 559px;
rotate: 90deg;
}

.table21{
  width: 36px;
position: absolute;
cursor: pointer;
top: 40px;
left: 609px;
rotate: 90deg;
}

.table22{
  width: 52px;
position: absolute;
cursor: pointer;
bottom: 41px;
left: 463px;
}

.table23{
  width: 52px;
position: absolute;
cursor: pointer;
bottom: 41px;
left: 531px;
}

.table24{
  width: 33px;
position: absolute;
cursor: pointer;
bottom: 41px;
left: 601px;
}

.table25{
  width: 36px;
  position: absolute;
  cursor: pointer;
  top: 96px;
  left: 532px;
  rotate: 90deg;
}

.table26{
  width: 43px;
position: absolute;
cursor: pointer;
top: 156px;
left: 432px;
rotate: 90deg;
}

.table27{
  width: 36px;
position: absolute;
cursor: pointer;
top: 164px;
left: 527px;
rotate: 90deg;
}

.table28{
  width: 45px;
position: absolute;
cursor: pointer;
bottom: 236px;
right: 609px;
rotate: 90deg;
}

.table29{
  width: 36px;
  position: absolute;
  cursor: pointer;
  bottom: 177px;
  right: 549px;
  rotate: 90deg;
}

.table30{
  width: 45px;
  position: absolute;
  cursor: pointer;
  bottom: 88px;
  right: 614px;
  rotate: 90deg;
}

.table31{
  width: 56px;
  position: absolute;
  cursor: pointer;
  bottom: 88px;
  right: 540px;
  rotate: 90deg;
}

.table32{
  width: 53px;
position: absolute;
cursor: pointer;
top: 200px;
left: 517px;
rotate: 90deg;
}


.table33{
  width: 53px;
position: absolute;
cursor: pointer;
top: 200px;
left: 588px;
rotate: 90deg;
}


.table34{
  width: 53px;
position: absolute;
cursor: pointer;
top: 292px;
left: 517px;
rotate: 90deg;
}


.table35{
  width: 53px;
position: absolute;
cursor: pointer;
top: 292px;
left: 588px;
rotate: 90deg;
}


.table36{
  width: 53px;
  position: absolute;
  cursor: pointer;
  top: 462px;
  left: 483px;
  rotate: 90deg;
}


.table37{
  width: 53px;
  position: absolute;
  cursor: pointer;
  top: 462px;
  left: 571px;
  rotate: 90deg;
}


.table38{
  width: 47px;
  position: absolute;
  cursor: pointer;
  bottom: 49px;
  left: 484px;
  rotate: 90deg;
}

.table39{
  width: 47px;
  position: absolute;
  cursor: pointer;
  bottom: 49px;
  left: 568px;
  rotate: 90deg;
}