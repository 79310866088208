.container {
    font-family: "Vazir";

}

.title{
    text-align: center;
    margin: 20px;
}

