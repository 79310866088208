.admincontainer{
  display: flex;
  font-family: 'shabnam';
}

.sidebar{
  flex: 2;
  text-align: center;
  padding: 8px 0 0 0;
  margin-top: 40px;
}

.orderstatus{
  cursor: pointer;
}

.orderstatus.cancel{
  color : red;
}

.sidebarbtn{
  background-color: gold;
  padding: 10px;
  cursor: pointer;
  border-bottom:2px solid gray ;
}

.sidebar-title{
  padding: 10px;
border-bottom:2px solid gray ;
}


.main{
  flex: 11;
text-align: center;
margin: 40px 40px 0 40px;
background-color: #e9ecef;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: center;
}

tr:nth-child(even){background-color: #f2f2f2}

th {
  background-color: #04AA6D;
  color: white;
}